a {
	text-decoration: none;
	background-color: transparent;
	color: #fff;
	font-family: Inter, 'sans-serif';
}
.brand {
	position: relative;
	z-index: 101;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	padding: 20px 32px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	color: #fff;
	font-weight: 400;
}

.logo-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	margin-left: 16px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: start;
	align-items: flex-start;
}

.logo-text {
	margin-top: -2px;
	font-size: 22px;
	line-height: 29px;
	font-weight: 700;
	text-decoration: none;
}

.logo-description {
	margin-top: 2px;
	margin-left: 1px;
	font-size: 9px;
	line-height: 14px;
	letter-spacing: 2.2px;
	text-transform: uppercase;
}

.logo {
	height: 38px;
}

img {
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

@media screen and (max-width: 991px) {
	.brand {
		padding-right: 20px;
		padding-left: 20px;
	}
}

@media screen and (max-width: 767px) {
	.logo-info {
		margin-left: 14px;
	}
	.brand {
		padding: 10px 16px;
	}
	.logo {
		height: 38px;
	}
}
@media screen and (max-width: 485px) {
	.logo-info {
		display: none;
	}
	.logo {
		height: 35px;
	}
}
