.bg-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 0;
	width: 100%;
	height: 100%;
	margin: auto;
	opacity: 0.7;
	object-fit: cover;
}

img {
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	border: 0;
}

.logo {
	height: 38px;
	color: #fff;
}


@media screen and (max-width: 991px) {
}
